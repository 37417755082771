import './index.scss'
import { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import { ProfileInfo, RecoveListType } from '../../helpers/dataTypes';
import { useParams } from 'react-router-dom';


var browser = {

  versions: function() {

    var u = navigator.userAgent;

    //  var     app = navigator.appVersion;

    return {

      trident: u.indexOf('Trident') > -1,                        /*IE内核*/

      presto: u.indexOf('Presto') > -1,          /*opera内核*/

      webKit: u.indexOf('AppleWebKit') > -1, /*苹果、谷歌内核*/

      gecko: u.indexOf('Gecko') > -1 && u.indexOf('KHTML') === -1,        /*火狐内核*/

      mobile: !!u.match(/AppleWebKit.*Mobile.*/),        /*是否为移动终端*/

      ios: !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/), /*ios终端*/

      android: u.indexOf('Android') > -1 || u.indexOf('Linux') > -1, /*android终端或者uc浏览器*/

      iPhone: u.indexOf('iPhone') > -1,          /*是否为iPhone或者QQHD浏览器*/

      iPad: u.indexOf('iPad') > -1,      /*是否iPad*/

      webApp: u.indexOf('Safari') === -1,          /*是否web应该程序，没有头部与底部*/

      souyue: u.indexOf('souyue') > -1,

      superapp: u.indexOf('superapp') > -1,

      weixin:u.toLowerCase().indexOf('micromessenger') > -1,

      Safari:u.indexOf('Safari') > -1

    };

  }(),
};


const PersonalCenter = (): JSX.Element => {
  const { userId } = useParams<any>();
  const [selectTabOrd, setSelectTabOrd] = useState<number>(0)
  const [videoBoxHeight, setVideoBoxHeight] = useState<number>(0)
  const [profileData, setProfileData] = useState<ProfileInfo>({
    user_id: '',
    profile_img_url: '',
    user_name: '',
    gender:0,
    birth_date:'',
    show_gender:0,
    show_birth_date:0,
    show_location:0,
    show_constellation:0,
    show_age:0,
    weight:0,
    fake:0,
    deleted:0,
    create_ts:0
  })
  const [postsList, setPostsList] = useState<RecoveListType>()
  const [likesList, setLikesList] = useState<RecoveListType>()
  const [loading, setLoading] = useState(true)
  const content = useRef<HTMLDivElement>(null)
  const postsBox = useRef<HTMLDivElement>(null)
  const likesBox = useRef<HTMLDivElement>(null)
  const domain = 'http://sandbox.api.nxglabs.io'
  const selectTab = (ord:number)=>{
    content.current?.scrollTo({
      top:0,
      behavior: 'smooth'
    })
    setTimeout(()=>{
      setSelectTabOrd(ord)
      if(ord === 0){
        setVideoBoxHeight(postsBox.current?.clientHeight|| 100)
      } else {
        setVideoBoxHeight(likesBox.current?.clientHeight|| 100)
      }
    }, 300)
  }

  const openApp = ()=>{
    console.log('%c🀀 browser.versions.ios', 'color: #1d5673; font-size: 20px;', browser.versions.ios);

    if (browser.versions.ios) {


          window.location.href = "realxyz://";
      
           setTimeout(function(){
      
                window.location.href = "https://apps.apple.com/cn/app/real-3d-lens-face-swap-camera/id1641227122";
      
                window.location.href = "https://apps.apple.com/cn/app/real-3d-lens-face-swap-camera/id1641227122";
      
           },2000)
      
      }else if (browser.versions.android){
      
           window.location.href = `real://profile/${userId}`;
      
           setTimeout(function(){
      
                window.location.href = "https://www.myreal.cc/";
      
           },2000)
      
      }
      
  }

  useEffect(()=>{
    // setVideoBoxHeight(postsBox.current?.clientHeight|| 100)
    getProfileInfo()
    getPostsList()
    getLikesList()

   
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])


  const getAge = (strBirthday:string)=>{
    var returnAge;
    // 根据生日计算年龄
    //以下五行是为了获取出生年月日，如果是从身份证上获取需要稍微改变一下
    var strBirthdayArr=strBirthday.split("-");
    var birthYear = Number(strBirthdayArr[0]);
    var birthMonth = Number(strBirthdayArr[1]);
    var birthDay = Number(strBirthdayArr[2]);
  
    const d = new Date();
    var nowYear = d.getFullYear();
    var nowMonth = d.getMonth() + 1;
    var nowDay = d.getDate();
  
    if(nowYear === birthYear){
      returnAge = 0;//同年 则为0岁
    }
    else{
      var ageDiff = nowYear - birthYear ; //年之差
      if(ageDiff > 0){
        if(nowMonth === birthMonth) {
          var dayDiff = nowDay - birthDay;//日之差
          if(dayDiff < 0)
          {
            returnAge = ageDiff - 1;
          }
          else
          {
            returnAge = ageDiff ;
          }
        }
        else
        {
          var monthDiff = nowMonth - birthMonth;//月之差
          if(monthDiff < 0)
          {
            returnAge = ageDiff - 1;
          }
          else
          {
            returnAge = ageDiff ;
          }
        }
      }
      else
      {
        returnAge = -1;//返回-1 表示出生日期输入错误 晚于今天
      }
    }
  
    return returnAge;//返回周岁年龄
  
  }

  const getAstro=(strBirthday:string)=>{
    var strBirthdayArr=strBirthday.split("-");
    // var birthYear = Number(strBirthdayArr[0]);
    var month = Number(strBirthdayArr[1]);
    var day = Number(strBirthdayArr[2]);

    var arr=[20,19,21,21,21,22,23,23,23,23,22,22];

    const ord = month*2-(day<arr[month-1]?2:0)
    const name = ['Capricorn', 'Aquarius', 'Pisces', 'Aries', 'Taurus', 'Gemini', 'Cancer','Leo','Virgo', 'Libra', 'Scorpio','Sagittarius','Capricorn']
    
    return name[ord/2];
  }


  const getProfileInfo = async ()=>{
    try {
      let res = await axios({
        method: 'get',
        url: `${domain}/user/v1/${userId}/profile`,
      });
      let data = res.data.data;
      setProfileData(data)
      setTimeout(()=>{
        setLoading(false)
      }, 1000)
    } catch (error:any) {
      console.log(111111111111,error.response); 
      setTimeout(()=>{
        setLoading(true)
      }, 1000)
    }
    
  }

  const getPostsList = async ()=>{
    try {
      let res = await axios({
        method: 'get',
        url: `${domain}/feed/v1/user/${userId}/posts?offset=0`,
      });
      let data = res.data.data;
      setPostsList(data)

    } catch (error:any) {
      console.log(error.response); 
    
    }
    
  }
  
   const getLikesList = async ()=>{
    try {
      let res = await axios({
        method: 'get',
        url: `${domain}/feed/v1/user/${userId}/liked-posts?offset=0`,
      });
      let data = res.data.data;
      setLikesList(data)
    
    } catch (error:any) {
      console.log(error.response); 
    }
    
  }
  return (
    <>
      <div className="personal_content">
          <div 
            className="loading"
            style={{
              zIndex: loading? '999999': '-10000',
              opacity: loading? 1:0
            }}
          >
            <div className="img_box">
              <img src={require('../../assets/loading.png').default} alt="" />
            </div>

            <h6>LOADING...</h6>
          </div>
          <div 
            className="content"
            style={{
              overflowY: loading? 'hidden': 'scroll',
            }}
            ref={content}
          >
              <div className="top_block"
                style={{
                  backgroundImage: profileData?.bg_img_urls
                  ?
                  profileData?.bg_img_urls?.length >0
                  ?`url(${profileData?.bg_img_urls[0]})`
                  :`url(${require('../../assets/m_special_picture_nome.png').default})`
                  : `url(${require('../../assets/m_special_picture_nome.png').default})`,
                }}
              >
                <div className="info">
                  <div className="avatar_box">
                    {
                      profileData?.profile_img_url === ''
                      ? <img src={require('../../assets/avatar.png').default} alt="" width={'100%'}/>
                      : <img src={profileData?.profile_img_url} alt="" width={'100%'}/>
                    }
                    
                  </div>
                  <div className="text">
                    <h6 className="name">
                    {profileData?.user_name}, {
                      profileData?.show_age === 1
                      ? getAge(profileData.birth_date)
                      :''
                    }
                    </h6>
                    <p className="id">Crushe ID：{profileData?.handle}</p>
                  </div>
                </div>
              </div>
              <div className="bottom_block">
                <div className="des">
                  {/* <p>Pastel Neotrash</p>
                  <p>Resident at @greyscale.tattoo</p>
                  <p>Via San Pio V n.7, Torino-Italy... <span>More</span></p> */}
                  {profileData?.bio}
                </div>
                <div className="tag">
                  {
                    profileData?.show_gender
                    ? <div className="item">
                        <img src={require('../../assets/Female.png').default} alt="" />
                        Female
                      </div>
                    :''
                  }
                  
                  {
                    profileData?.show_location === 1
                    ? <div className="item">
                        <img src={require('../../assets/location.png').default} alt="" />
                        LA
                      </div>
                    :''
                  }
                  {
                    profileData?.show_birth_date === 1 && profileData.birth_date !== ''
                    ? <div className="item">
                        <img src={require(`../../assets/${getAstro(profileData.birth_date)}.png`).default} alt="" />
                        {getAstro(profileData.birth_date)}
                      </div>
                    
                    : ''
                  }
                  
                </div>
                <div className="follow">
                  <button onClick={openApp}><span>+</span>Follow</button>
                </div>
                <div className="video_list">
                  <div className="tabTitle">
                    <div className={['item', selectTabOrd === 0 ? 'active':null].join(' ')} onClick={()=>{selectTab(0)}}>
                    Posts {postsList?.total_count?postsList?.total_count:0}
                    </div>
                    <div className={['item', selectTabOrd === 1 ? 'active':null].join(' ')} onClick={()=>{selectTab(1)}}>
                    Likes {likesList?.total_count?likesList?.total_count:0}
                    </div>
                  </div>
                  <div className="tabContent"
                    style={{
                      transform: selectTabOrd===0?'translateX(0vw)':'translateX(-100vw)',
                      height: videoBoxHeight===0?'auto':`${videoBoxHeight}px`
                    }}
                  >
                    <div className="postsBox" ref={postsBox}>
                      {
                        
                        postsList?.total_count
                        ?postsList?.items.map((el, index)=>{
                          return <div onClick={openApp} className="item" key={index}>
                          {
                            el.feed.asset_type === 2
                            ? <video  
                              preload="auto" 
                              loop 
                              muted 
                              playsInline 
                              autoPlay >
                                <source src={el.feed.asset_uri} type="video/mp4" />
                              </video>
                            : <img src={el.feed.asset_uri} alt=""/>
                          }
                            
                        </div>
                        })
                        : <div className="nodata">No Data</div>
                      }
                      
                    
                      
                      
                    </div>
                    <div className="likesBox" ref={likesBox}>
                      {
                        likesList?.total_count
                        ?likesList?.items.map((el, index)=>{
                          return <div onClick={openApp} className="item" key={index}>
                          {
                            el.feed.asset_type === 2
                            ? <video  
                              preload="auto" 
                              loop 
                              muted 
                              playsInline 
                              autoPlay >
                                <source src={el.feed.asset_uri} type="video/mp4" />
                              </video>
                            : <img src={el.feed.asset_uri} alt=""/>
                          }
                            
                        </div>
                        })
                        : <div className="nodata">No Data</div>
                      }
                      
                    </div>
                  </div>
                </div>
              </div>
              <div className="send_msg">
                <p>Meet new friends in Crushe.</p>
                <button onClick={openApp} className='SeeMore'>See More</button>
              </div>
            </div>
        
       
       
      </div>
    </>
  );
}

export default PersonalCenter;